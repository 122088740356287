
<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        :submit="saveData"
        :initialValues="initialValues"
        class="w-full form-col-2 pr-2"
        ref="form"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >student lease
            </label>
          </div>
        </div>
        <div>
          <div class="form-row">
            <div class="w-1/3 pr-8">
              <div class="flex flex-row justify-start mb-2">
                <button>
                  <CheckboxInputSmall
                    type="checkbox"
                    name="apartmentNumberAndBedroomCheckbox"
                    class="ml-0"
                  />
                </button>
                <div class="text-title">apartment number</div>
              </div>

              <TextField
                name="apartmentNumber"
                label=""
                type="number"
                class="ml-0"
              />
            </div>

            <div class="w-1/3 mx-4 px-4">
              <TextField
                name="bedroomNumber"
                label="bedroom number"
                type="number"
              />
            </div>

            <div class="w-1/3 pl-8">
              <div class="flex flex-row justify-start mb-2">
                <button>
                  <CheckboxInputSmall
                    type="checkbox"
                    name="floorplanCheckbox"
                    class="ml-0"
                  />
                </button>
                <div class="text-title">floorplan</div>
              </div>

              <TextField name="floorplan" class="ml-0" />
            </div>
          </div>

          <div class="form-row">
            <TextField
              name="rentAmount"
              label="your rent for the term is"
              placeholder="$"
              type="number"
            />
            <TextField name="payableIn" label="payable in" type="number" />
            <TextField
              name="installments"
              label="installments of"
              placeholder="$"
              type="number"
            />
          </div>

          <div class="form-row">
            <TextField
              name="bedroomTransferFee"
              label="bedroom transfer fee"
              placeholder="$"
              type="number"
            />
            <TextField
              name="unitTransferFee"
              label="unit transfer fee"
              placeholder="$"
              type="number"
            />
          </div>

          <div class="h-4"></div>

          <label class="form-section-sub-title">special provisions</label>
          <div>
            <text-input
              id="specialProvisions"
              v-model="specialProvisions"
              rows="5"
              multiline
            />
          </div>
        </div>
      </FinalForm>
    </div>

    <modal-footer :footer="footer" :primary="saveData"></modal-footer>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextField from "@/components/form/TextField";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import { FinalForm } from "vue-final-form";
import NotifyMixin from "@/mixins/NotifyMixin";
import TextInput from '@/components/ui/TextInput';

export default {
  mixins: [ModalNavigation, InitializeFormMixin, NotifyMixin],
  components: {
    Loader,
    ModalFooter,
    TextField,
    CheckboxInputSmall,
    FinalForm,
    TextInput,
  },
  computed: {
    initialValues() {
      return {
        apartmentNumberAndBedroomCheckbox: true,
        apartmentNumber: "",
        bedroomNumber: "",
        floorplanCheckbox: false,
        floorplan: "",
        rentAmount: "",
        payableIn: "",
        installments: "",
        bedroomTransferFee: "",
        unitTransferFee: "",
        specialProvisions: "",
      };
    },
  },
  data() {
    return {
      loading: false,
      footer: {
        primaryButton: "save",
      },
      apartmentNumberAndBedroomCheckbox: true,
      apartmentNumber: "",
      bedroomNumber: "",
      floorplanCheckbox: false,
      floorplan: "",
      rentAmount: "",
      payableIn: "",
      installments: "",
      bedroomTransferFee: "",
      unitTransferFee: "",
      specialProvisions: "",
    };
  },
  methods: {
    saveData() {
      this.$refs.form.formState.values.specialProvisions =
        this.specialProvisions;

      if (
        !this.$refs.form.formState.values.apartmentNumberAndBedroomCheckbox &&
        !this.$refs.form.formState.values.floorplanCheckbox
      ) {
        this.notifyError("Please select one of the checkboxes.");
      }
    },
  },
};
</script>

<style scoped>
input {
  accent-color: var(--highlightColor500) !important;
}
.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>
